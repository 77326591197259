var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('portal',{attrs:{"to":"body-top"}},[_c('h4',{staticClass:"text-center font-weight-bolder mb-1"},[_vm._v(" "+_vm._s(_vm.$t('Supplier and Customer Accounts'))+" ")])]),_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"search-props":_vm.getSearchProps(),"is-auto-suggest":true,"is-delete":true,"auto-suggest-add-params":_vm.autoSuggestAddParams(),"filter-class":new _vm.Customers(),"table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/getCustomerInAccountPage")
    },"row-clicked":_vm.redirectHomeCustomersView,"has-action":true,"create-page-url":{ name: 'home-customers-create' },"create-btn-dashed":true,"table-suggestion-configs":_vm.suggestionTableColumns,"is-suggestion-table":true,"auto-suggest-style":{width:'380px'},"is-reload-on-select-suggest-item":false,"trash-list-path":"home-customers-trash-list","can-create":_vm.ACCESS_ABILITY_FOR_ACCOUNTS,"can-show-trash-list":_vm.ACCESS_ABILITY_FOR_ACCOUNTS,"section-configs":{
      default:{
        onSelected: null,
      },
      name: 'default', type: 'auto-suggest-customer-section', limit: 3
    }},on:{"searchSelected":_vm.pushToSelectedItemByRouter,"exportCustomerInfo":function($event){return _vm.exportActionsForBatch({
      btnTitle: 'Export Customer Information',
      actionType: 'customer_information',
    })},"exportCustomerAddress":function($event){return _vm.exportActionsForBatch({
      btnTitle: 'Export Customer Addresses',
      actionType: 'customer_addresses',
    })},"exportCustomerContacts":function($event){return _vm.exportActionsForBatch({
      btnTitle: 'Export Customer Contacts',
      actionType: 'customer_contacts',
    })},"update-batch-selected":_vm.handleBatchSelected},scopedSlots:_vm._u([{key:"cell(cust_name_dba)",fn:function(ref){
    var data = ref.data;
return [_c('b-link',{staticClass:"text-body text-wrap",attrs:{"to":{ name: 'home-customers-view' , params: { id: data.item.id }}}},[_c('feather-icon',{staticStyle:{"margin-bottom":"5px"},attrs:{"icon":_vm.checkIconType(data),"size":"16"}}),_vm._v(" "+_vm._s(_vm.getCustomerName(data.item))+" ")],1)]}},{key:"cell(default_contact)",fn:function(ref){
    var data = ref.data;
return [_c('b-link',{staticClass:"text-body text-wrap",attrs:{"to":{ name: 'home-customers-contacts' , params: { id: data.item.id }}}},[_vm._v(" "+_vm._s(_vm.getData(data, 'fullname'))+" ")])]}},{key:"cell(phone_one)",fn:function(ref){
    var item = ref.data.item;
return [_c('b-link',{staticClass:"text-body text-wrap",attrs:{"to":{ name: 'home-customers-contacts' , params: { id: item.id }}}},[_vm._v(" "+_vm._s(item.default_contact ? _vm.getPhoneNumber(item.default_contact) : '—')+" ")])]}},{key:"cell(status)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.is_active === null ? '—' : data.item.is_active ? 'Active' : 'Inactive')+" ")]}},{key:"table-top-right-side-extras-prev",fn:function(ref){
    var data = ref.data;
return [_c('actions',{attrs:{"icon-name":"LExportIcon","title":"Export","b-drop-down-items":_vm.exportImportButtonItems},on:{"actionHandler":_vm.exportActions}}),_c('actions',{attrs:{"icon-name":"LImportIcon","title":"Import","b-drop-down-items":_vm.exportImportButtonItems},on:{"actionHandler":_vm.importActions}})]}},{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{staticClass:"v-select--custom selectInput flex-grow-1",attrs:{"id":"countryList","label":"label","options":_vm.supplierActionList},on:{"input":_vm.onSelectAction,"open":function($event){_vm.isRedirectCustomerPage = false}},model:{value:(_vm.customers[data.index].action),callback:function ($$v) {_vm.$set(_vm.customers[data.index], "action", $$v)},expression:"customers[data.index].action"}}),_c('b-button',{staticStyle:{"padding":"10px","margin-left":"10px"},attrs:{"variant":"success"},on:{"click":function($event){return _vm.goActionHandler(_vm.customers[data.index])}}},[_vm._v(" "+_vm._s(_vm.$t("Go"))+" ")])],1)]}}])}),_c('export-c-s-v',{ref:"export-c-s-v",attrs:{"module-name":_vm.MODULE_NAME}}),_c('import-c-s-v',{ref:"import-c-s-v",attrs:{"module-name":_vm.MODULE_NAME},on:{"refetchTable":_vm.refetchData}}),_c('export-c-s-v-batch',{ref:"export-c-s-v-batch",attrs:{"module-name":_vm.MODULE_NAME}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
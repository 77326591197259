<template>
  <b-modal
    :id="EXPORT_MODAL_BATCH_ID"
    centered
    hide-header
    body-class="csv-file-export-modal-body py-2"
    footer-class="csv-file-export-modal-footer"
    ok-variant="success"
    ok-only
    ok-title="Hide"
    modal-class="csv-file-export"
  >
    <div class="text-center mb-2 font-medium-5">
      <div>{{ $t('Do you want to export selected') }}</div>
      <span class="text-warning">{{ $t(` ${title}`) }}</span>
      <span>{{ $t(' CSV') }}</span>
    </div>
    <div class="d-flex justify-content-center w-100 mb-2">
      <b-button
        class="d-flex align-items-center justify-content-center"
        style="width: 200px; height: 40px"
        @click="exportButtonHandler(exportPath)"
      >
        <b-spinner
          v-if="isExporting"
          small
        />
        <span v-else>{{ $t('Export') }}</span>
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import config from '@/views/components/Modals/config'

export default {
  name: 'ExportCSVBatch',
  components: {
    BButton,
    BSpinner,
  },
  props: {
    moduleName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExporting: false,
    }
  },
  computed: {
    title() {
      return this.$store.state[this.moduleName].exportBatch.title
    },
    exportPath() {
      return this.$store.state[this.moduleName].exportPath
    },
    export() {
      return this.$store.state[this.moduleName].exportBatch
    },
  },
  methods: {
    hide() { this.$bvModal.hide(this.EXPORT_MODAL_BATCH_ID) },
    show() { this.$bvModal.show(this.EXPORT_MODAL_BATCH_ID) },
    exportButtonHandler(path) {
      this.isExporting = true
      // eslint-disable-next-line camelcase
      const {
        title, actionType: export_type, query, ids,
      } = this.export
      this.exportDataRequest(path, {
        ...query,
        title,
        export_type,
        ids,
      }, this.moduleName, this)
    },
  },
  setup() {
    const {
      download, exportDataRequest, EXPORT_MODAL_BATCH_ID,
    } = config()

    return {
      download,
      EXPORT_MODAL_BATCH_ID,
      exportDataRequest,
    }
  },
}
</script>
